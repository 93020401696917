<template>
  <div class="contact">
    <!-- 关于我们 -->
    <div :class="$store.state.isPhone ? 'phone export' : 'export'">
      <div class="WebMain">
        <p class="DivTitle">ThriverWise Network Information Technology Co., Ltd.</p>
        <div class="DivContent">
          <div class="left">
            <!-- <img src="../../assets/img/contact/logo.png" alt=""> -->
            <p class="p17">
              ThriverWise Network Information Technology Co., Ltd. specializes in software development services for enterprises and individuals. We offer industry solutions, APP development, enterprise management systems, and data analysis systems using advanced technologies like cloud computing and data mining. Our products are carefully crafted to meet the highest quality standards and support the digital transformation of businesses.
            </p>
          </div>
          <div class="right">
            <img src="../../assets/img/contact/关于我们banner备份.jpg" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="choose">
      <div class="WebMain">
        <p class="DivTitle">The trust and choice of our customers</p>
        <div class="DivContent">
          <div class="item" v-for="item in 12">
            <div class="topStyle"></div>
            <img :src="require(`../../assets/img/contact/icon (${item}).png`)" alt="">
          </div>
        </div>
      </div>
    </div>
    <div :class="$store.state.isPhone ? 'phone connection' : 'connection'">
      <div class="WebMain">
        <p class="DivTitle">We provide services for various types of businesses</p>
        <div class="DivContent">
          <div :class="$store.state.isPhone ? 'item wow bounceInRight' : 'item'" v-for="item in connectionList"
            :key="item.id">
            <div class="bg"></div>
            <div class="itemDiv">
              <div class="top"></div>
              <div class="bottom"></div>
              <div class="text">
                <div>
                  <p class="title p26">{{ item.title }}</p>
                  <p class="content p20">{{ item.content }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="connect">
      <img v-if="$store.state.isPhone" class="wow bounceIn" src="../../assets/img/contact/联系我们备份.jpg" alt="">
      <img v-else class="wow bounceIn" src="../../assets/img/contact/联系我们.jpg" alt="">
      
      <div class="WebMain">
        <div :class="$store.state.isPhone ? 'phone top' : 'top'"></div>
        <div :class="$store.state.isPhone ? 'phone bottom' : 'bottom'"></div>
        <div class="content">
          <p class="p34">Contact us</p>
          <p class="p24">Get free development plan and quote</p>
          <!-- <span>Q Q:18601036232</span> -->
          <span>Phone :0086 2885772552</span>
          <span>mobile:0086 18601036132</span>
          <span>WeChat:du18601036232</span>
          <!-- <span>Phone:18601036132</span> -->
          <span>Email:cs@zhihanyixing.com</span>
          <span>Address: Room 1503, Block C, Oriental Plaza, No. 229 East Avenue, Jinjiang District, Chengdu，China.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WOW from 'wow.js'
export default {
  data() {
    return {
      connectionList: [
        { id: 1, title: 'Small and medium-sized enterprises', content: '45% ', imgPath: 'connection (1).jpg' },
        { id: 2, title: 'Large and listed companies', content: '20%', imgPath: 'connection (2).jpg' },
        { id: 3, title: 'Government, schools, public institutions', content: '20%', imgPath: 'connection (3).jpg' },
        { id: 4, title: 'Hospitals ', content: '15%', imgPath: 'connection (4).jpg' },
      ]
    }
  },
  mounted() {
    var wow = new WOW({
      boxClass: 'wow',
      offset: 100,
      mobile: true,
      live: true
    });
    wow.init();
  }
}
</script>

<style lang="scss" scoped>
.contact {
  .export {
    background: #F5F5F5;
    padding-bottom: 60px;

    .DivContent {
      background: white;
      display: flex;
      flex-wrap: wrap;

      .left {
        padding: 38px 50px;
        box-sizing: border-box;
        width: 590px;

        img {
          width: 175px;

        }

        p {
          margin-top: 20px;
          color: #666666;
          line-height: 27px;
        }
      }

      .right {
        width: 590px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .phone.export {
    .DivContent .left {
      padding: 20px 15px;

      p {
        margin-top: 0;
      }
    }
  }

  .choose {
    padding-bottom: 60px;
    background: url('../../assets/img/contact/bg.png');
    background-size: cover;

    .DivContent {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .item {
        width: 295px;
        height: 117px;
        border: 1px solid #DEDEDE;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &:hover {
          .topStyle {
            width: 100%;
            transition: all .5s;
          }
        }

        .topStyle {
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 7px;
          background-color: var(--custom-color);
        }

        img {
          max-width: 160px;
        }
      }
    }
  }

  .connection {
    background: #F5F5F5;
    padding-bottom: 60px;

    .DivContent {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .item {
        width: 582px;
        height: 255px;
        position: relative;
        margin-bottom: 15px;
        background-size: cover;
        background-repeat: no-repeat;

        .bg {
          background: #000000;
          opacity: 0.45;
          height: 100%;
          width: 100%;
          // position: absolute;
        }

        .itemDiv {
          z-index: 1;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;

          .text {
            height: 100%;
            width: 100%;
            // margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            align-items: center;


            div {
              width: 100%;
              text-align: center;

              p {
                color: white;
              }

              .title {
                margin-bottom: 14px;
              }

            }


          }


        }

        &:first-child {
          background-image: url('../../assets/img/contact/connection\ \(1\).jpg');

        }

        &:nth-child(2) {
          background-image: url('../../assets/img/contact/connection\ \(2\).jpg');
        }

        &:nth-child(3) {
          background-image: url('../../assets/img/contact/connection\ \(3\).jpg');
        }

        &:nth-child(4) {
          background-image: url('../../assets/img/contact/connection\ \(4\).jpg');
        }

        .top,
        .bottom {
          position: absolute;
          width: 100%;
          height: 30px;
          box-sizing: border-box;
          z-index: 1;

          &:before,
          &:after {
            content: '';
            display: inline-block;
            background: url('https://test2.zhihanyixing.com/api/image/5086bf3de2d841d390a9f01104f8fb3f5086bf3de2d841d390a9f01104f8fb3f.png') no-repeat;
            background-size: contain;
            width: 21px;
            height: 21px;
            position: absolute;
            // transition: all .3s;
          }

          &:before {
            left: 130px
          }

          &:after {
            right: 130px
          }
        }

        .bottom {
          bottom: 45px;

          &:after {
            transform: rotate(180deg);
          }

          &:before {
            transform: rotate(270deg);
          }
        }

        .top {
          top: 45px;

          &:after {
            transform: rotate(90deg);
          }
        }

        &:hover {

          .top,
          .bottom {

            &:before,
            &:after {
              transition: all .3s;
              background: url('https://test2.zhihanyixing.com/api/image/7c200b8c1fa047f298aa116e22c25bf37c200b8c1fa047f298aa116e22c25bf3.png') no-repeat;
              background-size: contain;
              width: 21px;
              height: 21px;
              // transform: translateY(-20px);

            }


          }

          .top {
            &:before {
              transform: translate(-20px, -15px);
            }

            &:after {
              transform: translate(20px, -15px) rotate(90deg);
            }
          }

          .bottom {
            &:before {
              transform: translate(-20px, 25px) rotate(270deg);
            }

            &:after {
              transform: translate(20px, 25px) rotate(180deg);
            }
          }
        }

      }
    }
  }

  .phone.connection {

    .DivContent .item .top:before,
    .DivContent .item .bottom:before {
      left: 70px;
    }

    .DivContent .item .top:after,
    .DivContent .item .bottom:after {
      right: 70px;
    }
  }

  .connect {
    background: #2C3135;
    color: #D2D2D2;
    position: relative;

    .WebMain {
      position: relative;

      .content {
        padding: 130px 190px;

        span {
          display: block;
          margin-top: 22px;
        }

        p {
          margin-bottom: 20px;
        }
      }

      .phone.top,
      .phone.bottom {

        &:before,
        &:after {
          width: 40px;
          height: 40px;

          &:before {
            left: 0px
          }

          &:after {
            right: 0px
          }
        }
        
      }
      .phone.bottom {
          bottom: 50px;

          
        }

        .phone.top {
          top: 50px;

        }

      .top,
      .bottom {
        position: absolute;
        width: 100%;
        height: 70px;
        box-sizing: border-box;

        &:before,
        &:after {
          content: '';
          display: inline-block;
          background: url('https://test2.zhihanyixing.com/api/image/1305c8c2377c4fbd8efc99728d60bf8c1305c8c2377c4fbd8efc99728d60bf8c.png') no-repeat;
          background-size: contain;
          width: 70px;
          height: 70px;
          position: absolute;
          // transition: all .3s;
        }

        &:before {
          left: 38px
        }

        &:after {
          right: 38px
        }
      }

      .bottom {
        bottom: 100px;

        &:after {
          transform: rotate(180deg);
        }

        &:before {
          transform: rotate(270deg);
        }
      }

      .top {
        top: 100px;

        &:after {
          transform: rotate(90deg);
        }
      }


    }

    img {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }
}

@media screen and (min-width:1180px) {
  .contact .connection .DivContent .item {

    &:first-child,
    &:nth-child(3) {
      margin-right: 15px;
    }
  }
}

@media screen and (max-width:500px) {
  .contact .choose .DivContent .item {
    width: 150px;
    height: 117px;

    img {
      max-width: 120px;
    }
  }
}

@media screen and (max-width:700px) {
  .contact .connect .WebMain .content {
    padding: 130px 60px
  }
}</style>